import Vue from 'vue';
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

// Função para verificar se o usuário está autenticado
function isAuthenticated() {
  return !!(localStorage.getItem('authToken') || sessionStorage.getItem('authToken'));
}

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: "history",
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});


// Guarda de rota para verificar a autenticação
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Se a rota requer autenticação
    if (isAuthenticated()) {
      next(); // Se o token estiver presente, continue para a rota
    } else {
      next('/login'); // Se não estiver autenticado, redirecione para a página de login
    }
  } else {
    next(); // Se a rota não requer autenticação, continue para a rota
  }
});


export default router;
